import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import tw, { styled } from "twin.macro";

import Bike from "../assets/bike.svg";
import Coffee from "../assets/coffee.svg";
import Pin from "../assets/pin.svg";
import Pub from "../assets/pub.svg";
import Shop from "../assets/shop.svg";
import Station from "../assets/station.svg";

const IconContainer = styled.div`
  svg {
    ${tw`h-10 w-10 fill-current mr-3`}
  }
`;

const POI = ({ poi }) => {
  let PoiIcon;

  switch (poi.type) {
    case "shop":
      PoiIcon = Shop;
      break;
    case "station":
      PoiIcon = Station;
      break;
    case "bike":
      PoiIcon = Bike;
      break;
    case "pub":
      PoiIcon = Pub;
      break;
    case "cafe":
      PoiIcon = Coffee;
      break;
    default:
      PoiIcon = Pin;
  }

  return (
    <div tw="flex items-center">
      <IconContainer>
        <PoiIcon />
      </IconContainer>
      <div>
        {poi.name}, {poi.near}
        <div tw="text-gray-600 text-xs">
          <OutboundLink href={poi.google} tw="underline">
            Google Maps
          </OutboundLink>
          {poi.website && (
            <>
              {" · "}
              <OutboundLink href={poi.website} tw="underline">
                Website
              </OutboundLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default POI;
