import React, { useState, useEffect, useCallback } from "react";
import ReactMapGL, {
  Source,
  Layer,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  Marker,
  Popup
} from "react-map-gl";
import useResizeObserver from "use-resize-observer/polyfilled";
import mapboxPolyline from "@mapbox/polyline";
import WebMercatorViewport from "viewport-mercator-project";
import turfBbox from "@turf/bbox";
import along from "@turf/along";
import { lineString } from "@turf/helpers";
import { AttributionControl } from "mapbox-gl";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import styled from "styled-components";
import tw, { theme } from "twin.macro";

import POI from "./POI";
import { MAP_STYLE } from "../constants";
import SolidPin from "../assets/pin-solid.svg";

import "mapbox-gl/dist/mapbox-gl.css";

const InteractiveMapContainer = styled.div`
  ${tw`w-full h-full flex items-center justify-center`}

  /* Fix for https://github.com/visgl/react-map-gl/issues/1067 */
  .mapboxgl-user-location-dot {
    top: -100% !important;
    left: -100% !important;
  }
`;

const MarkerDot = tw.circle`text-blue-600 fill-current stroke-white stroke-2`;

const InteractiveMap = ({ polyline, maxWidth, markerDistance, pois }) => {
  const { ref, width, height } = useResizeObserver();
  const [mapNotSupported, setMapNotSupported] = useState(false);
  const [showPoiPopup, setShowPoiPopup] = useState(null);

  const mapRef = useCallback((node) => {
    if (node !== null) {
      const map = node.getMap();
      if (map) {
        map.addControl(
          new AttributionControl({
            compact: true
          })
        );
      } else {
        setMapNotSupported(true);
        trackCustomEvent({
          category: "Interactive map",
          action: "Failed to load"
        });
      }
    }
  }, []);

  const [viewport, setViewport] = useState(null);

  const [geoJson, setGeoJson] = useState(null);

  const [
    [[startLongitude, startLatitude], [endLongitude, endLatitude]],
    setMarkerCoordinates
  ] = useState([[], []]);

  useEffect(() => {
    const decodedGeoJson = mapboxPolyline.toGeoJSON(polyline);
    setGeoJson(decodedGeoJson);
  }, [polyline]);

  useEffect(() => {
    if (viewport || !width || !height || !geoJson) return;

    const [minX, minY, maxX, maxY] = turfBbox(geoJson);

    const newViewport = new WebMercatorViewport({
      width: maxWidth ? Math.min(width, maxWidth) : width,
      height
    }).fitBounds(
      [
        [minX, minY],
        [maxX, maxY]
      ],
      {
        padding: 20
      }
    );

    setMarkerCoordinates([
      geoJson.coordinates[0],
      geoJson.coordinates[geoJson.coordinates.length - 1]
    ]);

    setViewport(newViewport);
  }, [viewport, width, maxWidth, height, geoJson]);

  let markerCoordinates;

  if (geoJson && markerDistance && markerDistance >= 0) {
    const {
      geometry: {
        coordinates: [longitude, latitude]
      }
    } = along(lineString(geoJson.coordinates), markerDistance);

    markerCoordinates = { latitude, longitude };
  }

  return (
    <InteractiveMapContainer ref={ref}>
      {mapNotSupported
        ? "Map not supported"
        : viewport && (
            <ReactMapGL
              {...viewport}
              width={width}
              height={height}
              onViewportChange={setViewport}
              mapStyle={`mapbox://styles/${MAP_STYLE}`}
              mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_KEY}
              attributionControl={false}
              onClick={() => setShowPoiPopup(null)}
              ref={mapRef}
            >
              <Source type="geojson" data={geoJson}>
                <Layer
                  type="line"
                  paint={{
                    "line-color": theme`colors.brand.500`,
                    "line-width": 3
                  }}
                  layout={{
                    "line-join": "round"
                  }}
                />
              </Source>
              {markerCoordinates && (
                <Marker
                  latitude={markerCoordinates.latitude}
                  longitude={markerCoordinates.longitude}
                  offsetLeft={-7}
                  offsetTop={-7}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <MarkerDot cx="7" cy="7" r="5" />
                  </svg>
                </Marker>
              )}
              {pois.map((poi, index) => (
                <Marker
                  key={index}
                  latitude={poi.coordinates[0]}
                  longitude={poi.coordinates[1]}
                  offsetLeft={-7.75}
                  offsetTop={-20}
                >
                  <SolidPin
                    onClick={() => setShowPoiPopup(index)}
                    tw="text-blue-600 fill-current cursor-pointer"
                    width={15.5}
                    height={20}
                  />
                </Marker>
              ))}
              <Marker
                latitude={endLatitude}
                longitude={endLongitude}
                offsetLeft={-7.75}
                offsetTop={-20}
                captureDrag={false}
                captureClick={false}
                captureDoubleClick={false}
              >
                <SolidPin
                  tw="text-red-600 fill-current"
                  width={15.5}
                  height={20}
                />
              </Marker>
              <Marker
                latitude={startLatitude}
                longitude={startLongitude}
                offsetLeft={-7.75}
                offsetTop={-20}
                captureDrag={false}
                captureClick={false}
                captureDoubleClick={false}
              >
                <SolidPin
                  tw="text-green-600 fill-current"
                  width={15.5}
                  height={20}
                />
              </Marker>
              {showPoiPopup !== null && (
                <Popup
                  latitude={pois[showPoiPopup].coordinates[0]}
                  longitude={pois[showPoiPopup].coordinates[1]}
                  closeOnClick={false}
                  offsetTop={-20}
                  dynamicPosition={false}
                  onClose={() => setShowPoiPopup(null)}
                >
                  <div tw="p-1">
                    <POI poi={pois[showPoiPopup]} />
                  </div>
                </Popup>
              )}
              <div tw="absolute left-0 m-4">
                <GeolocateControl
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                />
              </div>
              <div tw="absolute right-4 top-4">
                <FullscreenControl tw="right-0" />
                <NavigationControl tw="right-0 top-12" />
              </div>
            </ReactMapGL>
          )}
    </InteractiveMapContainer>
  );
};

export default InteractiveMap;
