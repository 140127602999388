import React, { useState } from "react";
import { graphql } from "gatsby";

import tw, { styled } from "twin.macro";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import InteractiveMap from "../components/InteractiveMap";
import DownloadButton from "../components/DownloadButton";
import ElevationProfile from "../components/ElevationProfile";

import Distance from "../assets/distance.svg";
import Elevation from "../assets/elevation.svg";

import POI from "../components/POI";
import PaddedContainer from "../components/PaddedContainer";

const RouteDescription = styled.div`
  ${tw`pt-2`}

  p:not(:last-child) {
    ${tw`my-2`}
  }

  a {
    ${tw`underline`}
  }
`;

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const [markerDistance, setMarkerDistance] = useState(null);

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={`A ${frontmatter.file.childGpx.length.toLocaleString([], {
          maximumFractionDigits: 1
        })} km cycle route on routes.cc`}
      />
      <div tw="py-6 flex-grow">
        <PaddedContainer tw="flex flex-wrap justify-between">
          <div>
            <h1 tw="text-4xl font-bold">{frontmatter.title}</h1>
            <div tw="text-gray-600 -mt-1">
              <div>
                <Distance
                  tw="h-6 w-6 inline-block fill-current"
                  role="img"
                  aria-label="Distance"
                />{" "}
                <span tw="font-bold">
                  {`${frontmatter.file.childGpx.length.toLocaleString([], {
                    maximumFractionDigits: 1
                  })} km`}
                </span>
                <Elevation
                  tw="h-5 w-5 inline-block fill-current ml-5 mr-1"
                  role="img"
                  aria-label="Elevation"
                />{" "}
                <span tw="font-bold text-lg">
                  {`${frontmatter.file.childGpx.totalElevation.toLocaleString(
                    [],
                    {
                      maximumFractionDigits: 0
                    }
                  )} m`}
                </span>
              </div>
            </div>
          </div>
          <div tw="mt-3">
            <DownloadButton url={frontmatter.file.publicURL} />
          </div>
        </PaddedContainer>
        <div
          style={{
            paddingLeft: "env(safe-area-inset-left)",
            paddingRight: "env(safe-area-inset-right)"
          }}
        >
          <div
            style={{ height: 500, maxHeight: "60vh", maxWidth: 1000 }}
            tw="mt-6 mx-auto"
          >
            <InteractiveMap
              polyline={frontmatter.file.childGpx.polyline}
              maxWidth={800}
              markerDistance={markerDistance}
              pois={frontmatter.pois}
            />
          </div>
        </div>
        <PaddedContainer tw="mt-6">
          <ElevationProfile
            defaultWidth={784}
            height={150}
            elevations={frontmatter.file.childGpx.elevations}
            markerDistance={markerDistance}
            onMarkerDistanceChange={setMarkerDistance}
          />
          <h2 tw="text-lg font-bold mt-6">On the route</h2>
          <RouteDescription dangerouslySetInnerHTML={{ __html: html }} />
          <div tw="mt-6">
            {frontmatter.pois.map((poi, index) => (
              <div key={index} tw="mb-3">
                <POI poi={poi} />
              </div>
            ))}
          </div>
          <div tw="mt-10 mb-2">
            <DownloadButton url={frontmatter.file.publicURL} />
          </div>
          <div tw="text-sm mb-4 font-bold text-gray-600">
            All routes have been tested and have cuesheets
          </div>
        </PaddedContainer>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        file {
          childGpx {
            length
            polyline
            elevations
            totalElevation
          }
          publicURL
        }
        pois {
          name
          near
          coordinates
          type
          google
          website
        }
      }
    }
  }
`;
