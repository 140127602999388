import React from "react";
import useResizeObserver from "use-resize-observer/polyfilled";
import { extent } from "d3-array";
import { scaleLinear } from "d3-scale";
import { line as d3Line, area as d3Area } from "d3-shape";
import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
  ${tw`w-full select-none`}
`;

const Chart = styled.svg`
  ${tw`pointer-events-none`}
`;

const Line = styled.path`
  ${tw`text-gray-600 stroke-current stroke-2`}
  fill: none;
`;

const Fill = styled.path`
  ${tw`text-gray-100 fill-current opacity-75`};
`;

const Axis = styled.line`
  ${tw`text-gray-600 stroke-current`}
`;

const TickLabel = styled.text`
  ${tw`text-gray-600 fill-current`}
  font-size: 10px;
`;

const LeftTickLabel = styled(TickLabel)`
  text-anchor: end;
`;

const BottomTickLabel = styled(TickLabel)`
  text-anchor: middle;
`;

const TickLine = styled.line`
  ${tw`text-gray-400 stroke-current`}
`;

const GridLine = styled.line`
  ${tw`text-gray-500 stroke-current`}
`;

const DistanceMarker = styled.line`
  ${tw`text-brand-500 stroke-current stroke-1`}
`;

const ElevationProfile = ({
  elevations,
  defaultWidth,
  height,
  onMarkerDistanceChange,
  markerDistance
}) => {
  const { ref, width = defaultWidth } = useResizeObserver();

  const margin = {
    top: 0,
    bottom: 25,
    left: 45,
    right: 20
  };

  const xScale = scaleLinear()
    .domain(extent(elevations, (d) => d[0]))
    .range([margin.left, width - margin.right]);

  const yScale = scaleLinear()
    .domain(extent(elevations, (d) => d[1]))
    .nice()
    .range([height - margin.bottom, margin.top]);

  const line = d3Line()
    .x((d) => xScale(d[0]))
    .y((d) => yScale(d[1]));

  const area = d3Area()
    .x((d) => xScale(d[0]))
    .y0(height - margin.bottom)
    .y1((d) => yScale(d[1]));

  const xTicksCount = (width - margin.left - margin.right) / 70;

  const xTicks = xScale.ticks(xTicksCount).map((d) =>
    xScale(d) < width ? (
      <g
        key={d}
        transform={`translate(${xScale(d)},${height - margin.bottom})`}
      >
        <BottomTickLabel transform="translate(0, 20)">{d} km</BottomTickLabel>
        <TickLine x1="0" x2="0" y1="0" y2="5" />
        <GridLine
          transform={`translate(0,-${height - margin.top - margin.bottom})`}
          x1="0"
          x2="0"
          y1="0"
          y2={height - margin.top - margin.bottom}
        />
      </g>
    ) : null
  );

  const yTicks = yScale.ticks(5).map((d) =>
    yScale(d) > 10 && yScale(d) < height ? (
      <g key={d} transform={`translate(${margin.left},${yScale(d)})`}>
        <LeftTickLabel x="-10" y="3">
          {d} m
        </LeftTickLabel>
        <TickLine x1="0" x2="5" y1="0" y2="0" transform="translate(-5,0)" />
        <GridLine
          x1="0"
          x2={width - margin.left - margin.right}
          y1="0"
          y2="0"
        />
      </g>
    ) : null
  );

  const distanceMarker = markerDistance !== null && (
    <DistanceMarker
      transform={`translate(${xScale(markerDistance)},0)`}
      x1="0"
      x2="0"
      y1="0"
      y2={height - margin.top - margin.bottom}
    />
  );

  const handleMouseMove = ({ nativeEvent: { offsetX } }) => {
    if (offsetX < margin.left || offsetX >= width - margin.right) {
      onMarkerDistanceChange(null);
      return;
    }

    const distance = xScale.invert(offsetX);

    onMarkerDistanceChange(distance);
  };

  return (
    <Container
      ref={ref}
      style={{ height }}
      onMouseMove={handleMouseMove}
      role="presentation"
    >
      <Chart width={width} height={height}>
        <g>
          {xTicks}
          {yTicks}
        </g>
        <Fill d={area(elevations)} />
        <Line d={line(elevations)} />
        {distanceMarker}
        <Axis
          x1={margin.left}
          y1={margin.top}
          x2={margin.left}
          y2={height - margin.bottom}
        />
        <Axis
          x1={margin.left}
          y1={height - margin.bottom}
          x2={width - margin.right}
          y2={height - margin.bottom}
        />
      </Chart>
    </Container>
  );
};

export default ElevationProfile;
